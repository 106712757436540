var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-default mt-4 d-flex flex-column",class:{
    'justify-center align-center': _vm.centerTitle,
  }},[_vm._v(" "+_vm._s(_vm.description)+" "),_vm._t("content",function(){return [_c('div',{staticClass:"d-flex flex-column",class:{
        'justify-center align-center': _vm.centerContent,
      }},[_c('div',{staticClass:"d-flex flex-column",class:{
          'text-h4': !_vm.small,
          'text-h6': _vm.small,
          'justify-center align-center': _vm.centerValue,
        }},[_vm._v(" "+_vm._s(_vm.localValue)+" ")])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }