<template>
  <div >
    <v-radio-group
      :disabled="disabled"
      class="ml-3"
      v-if="type === 'row'"
      @change="handleChangeRadioSelected"
      v-model="itemSelected"
      row
    >
      <div class="justify-center aling-center d-flex flex-column">
        {{ title }}
        <v-radio
          class="ml-3"
          v-for="i in items"
          :key="i.key"
          :label="i.name"
          :value="i.value"
        ></v-radio>
      </div>
    </v-radio-group>
    <v-radio-group
      v-else-if="type === 'column'"
      @change="handleChangeRadioSelected"
      v-model="itemSelected"
      column
    >
      {{ title }}

      <v-radio
        v-for="i in items"
        :key="i.key"
        :label="i.name"
        :value="i.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioGroupComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    default: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "row",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemSelected: this.default,
    };
  },
  methods: {
    handleChangeRadioSelected(value) {
      this.$emit("value-selected", value);
    },
  },
  watch:{
    default(newVal){
      this.itemSelected = newVal
    }
  }
};
</script>

<style>
</style>